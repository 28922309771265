.preview-image {
  display: flex;
  width: 90%;
  overflow: auto;
  text-align: center !important;
  align-items: center !important;
  align-self: center !important;
}

.preview-image > img {
  object-fit: cover !important;
  width: 250px;
  height: 250px !important;
  margin-right: 14px;
  border: 0px solid;
  -webkit-box-shadow: -5px 7px 35px 8px rgba(230, 230, 230, 1);
  -moz-box-shadow: -5px 7px 35px 8px rgba(230, 230, 230, 1);
  box-shadow: -5px 7px 35px 8px rgba(230, 230, 230, 1);
}

.preview-image > div > img {
  object-fit: cover !important;
  width: 250px;
  height: 250px !important;
  margin-right: 14px;
  border: 0px solid;
  -webkit-box-shadow: -5px 7px 35px 8px rgba(230, 230, 230, 1);
  -moz-box-shadow: -5px 7px 35px 8px rgba(230, 230, 230, 1);
  box-shadow: -5px 7px 35px 8px rgba(230, 230, 230, 1);
}
